import { template as template_53671813d1a4443faf8886b39f03f24c } from "@ember/template-compiler";
import DButton from "discourse/components/d-button";
const BackButton = template_53671813d1a4443faf8886b39f03f24c(`
  <DButton
    @action={{@onGoBack}}
    @label="topic.timeline.back"
    @title="topic.timeline.back_description"
    class="btn-primary btn-small back-button"
  />
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default BackButton;
