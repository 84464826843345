import { template as template_bc8462b5c1dc436b9f2d5307186b85ee } from "@ember/template-compiler";
import concatClass from "discourse/helpers/concat-class";
const FKSection = template_bc8462b5c1dc436b9f2d5307186b85ee(`
  <div class={{concatClass "form-kit__section" @class}} ...attributes>
    {{#if @title}}
      <h2 class="form-kit__section-title">{{@title}}</h2>
    {{/if}}

    {{#if @subtitle}}
      <span class="form-kit__section-subtitle">{{@subtitle}}</span>
    {{/if}}

    {{yield}}
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKSection;
